import React from 'react';
import './Layout.css';

export default function Layout({ children }) {
  return (
    <div className="App">
      {children}
      <footer className="powered-by-footer">
        <p>
        🌱 <br />Powered by <br /> <a 
            href="https://coinset.org"
            target="_blank"
            rel="noopener noreferrer"
          >coinset.org
          </a>
        </p>
        <br />
        <a 
            href="https://x.com/NavRoudsari"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          ><svg className="x-logo" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
          <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
        </svg> 
        <p>@NavRoudsari </p>
          </a>
      </footer>
    </div>
  );
} 