import React, { useEffect, useRef, useState } from 'react';
import './CRTDisplay.css';
import { PLAY_DIRECTION } from '../App'; // Import PLAY_DIRECTION from App.js

export function CRTDisplay({ 
  block, 
  currentNote, 
  currentChar, 
  currentIndex, 
  headerHash, 
  audioData, 
  isTestnet,
  isPlaying,
  isFastMoving,
  playDirection,
  currentSpeed
}) {
  const terminalRef = useRef(null);
  const notesSectionRef = useRef(null);
  const asciiVisualizerRef = useRef(null);
  const animationFrameRef = useRef(null);
  const [readSpeed, setReadSpeed] = useState(0);

  useEffect(() => {
    if (block && terminalRef.current) {
      displayBlockInfo(block, isTestnet);
    }
  }, [block, isTestnet]);

  useEffect(() => {
    updateNotesSection();
  }, [currentNote, currentChar, currentIndex, headerHash]);

  useEffect(() => {
    if (audioData) {
      updateAsciiVisualizer(audioData);
    }
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [audioData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateReadSpeed();
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [isPlaying, isFastMoving, playDirection, currentSpeed]);

  const updateReadSpeed = () => {
    let speed = 0;
    if (isPlaying || isFastMoving) {
      if (playDirection === PLAY_DIRECTION.PLAY) {
        // Add variation between 27.1 and 28.1 bytes/second
        speed = 27.1 + Math.random();
      } else if (playDirection === PLAY_DIRECTION.FAST_FORWARD) {
        speed = (27.1 + Math.random()) * currentSpeed; // Use average of 27.1 and 28.1
      } else if (playDirection === PLAY_DIRECTION.REVERSE) {
        speed = -(27.1 + Math.random()) * currentSpeed; // Use average of 27.1 and 28.1
      }
    }
    setReadSpeed(speed);
    updateSpeedDisplay(speed);
  };

  const updateSpeedDisplay = (speed) => {
    const speedDisplayElement = document.querySelector('.speed-display');
    if (speedDisplayElement) {
      const direction = speed < 0 ? '◀' : (speed > 0 ? '▶' : '■');
      speedDisplayElement.textContent = `${Math.abs(speed).toFixed(1)} B/s ${direction}`;
    }
  };

  const formatXCH = (mojos, isTestnet) => {
    const xch = mojos / 1000000000000; // Convert mojos to XCH
    return xch.toFixed(12) + (isTestnet ? " TXCH" : " XCH");
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const createHashElement = (label, hash) => {
    const fullElement = document.createElement('div');
    fullElement.className = 'hash-line';
    
    const labelElement = document.createElement('div');
    labelElement.textContent = `${label}:`;
    labelElement.className = 'hash-label';
    fullElement.appendChild(labelElement);

    const hashContainer = document.createElement('div');
    hashContainer.className = 'hash-container';

    const hashElement = document.createElement('span');
    hashElement.className = 'copyable-hash';
    hashElement.textContent = hash || 'N/A';
    hashContainer.appendChild(hashElement);

    const copyIcon = document.createElement('span');
    copyIcon.className = 'copy-icon';
    copyIcon.title = 'Click to copy full hash';
    copyIcon.innerHTML = '📋'; // Unicode clipboard icon
    copyIcon.onclick = (e) => {
      e.stopPropagation(); // Prevent triggering the parent's click event
      copyToClipboard(hash);
    };
    hashContainer.appendChild(copyIcon);

    fullElement.appendChild(hashContainer);

    return fullElement;
  };

  const highlightCurrentChar = (hash, currentIndex) => {
    if (!hash || currentIndex === null || currentIndex === -1) return hash;
    if (currentIndex >= hash.length || currentIndex < 0) return hash;
    return (
      `${hash.slice(0, currentIndex)}<span class="highlight-char">${hash[currentIndex]}</span>${hash.slice(currentIndex + 1)}`
    );
  };

  const createAsciiVisualizer = () => {
    const visualizerElement = document.createElement('pre');
    visualizerElement.className = 'ascii-visualizer';
    asciiVisualizerRef.current = visualizerElement;
    return visualizerElement;
  };

  const updateAsciiVisualizer = (audioData) => {
    if (!asciiVisualizerRef.current) return;

    const CHAR_LEVELS = ' ▁▂▃▄▅▆▇█';
    const VISUALIZER_WIDTH = 32; // Adjust this value to change the width of the visualizer

    let visualization = '';

    for (let i = 0; i < VISUALIZER_WIDTH; i++) {
      const dataIndex = Math.floor(i * audioData.length / VISUALIZER_WIDTH);
      const value = audioData[dataIndex];
      const charIndex = Math.floor((value / 255) * (CHAR_LEVELS.length - 1));
      visualization += CHAR_LEVELS[charIndex];
    }

    asciiVisualizerRef.current.textContent = visualization;
    animationFrameRef.current = requestAnimationFrame(() => updateAsciiVisualizer(audioData));
  };

  const displayBlockInfo = (block, isTestnet) => {
    const terminal = terminalRef.current;
    terminal.innerHTML = '';

    // Add the notes section container
    const notesSectionElement = document.createElement('div');
    notesSectionElement.className = 'current-note-section';
    notesSectionElement.innerHTML = '';
    notesSectionRef.current = notesSectionElement;
    terminal.appendChild(notesSectionElement);

    updateNotesSection();

    // Create block record section
    const blockRecordSection = document.createElement('div');
    blockRecordSection.className = 'block-record-section';
    terminal.appendChild(blockRecordSection);

    const titleElement = document.createElement('h2');
    titleElement.textContent = 'Block Record Details';
    titleElement.className = 'crt-title';
    blockRecordSection.appendChild(titleElement);

    // Create block height line
    const heightLineElement = document.createElement('div');
    heightLineElement.innerHTML = isTestnet ? 
      `Block Height: <a href="https://alltheblocks.net/testnet11/height/${block.height}" target="_blank" rel="noopener noreferrer" class="crt-link">${block.height} 🌐</a>` : 
      `Block Height: <a href="https://spacescan.io/block/${block.height}" target="_blank" rel="noopener noreferrer" class="crt-link">${block.height} 🌐</a>`;
    heightLineElement.className = 'line';
    heightLineElement.style.animation = `typing 1s steps(${heightLineElement.textContent.length}, end) 0s forwards`;
    blockRecordSection.appendChild(heightLineElement);

    // Create header hash line
    const headerHashElement = createHashElement('Header Hash', block.header_hash);
    headerHashElement.className += ' line';
    headerHashElement.style.animation = `typing 1s steps(40, end) 0.1s forwards`;
    blockRecordSection.appendChild(headerHashElement);

    // Create farmer puzzle hash line
    const farmerPuzzleHashElement = createHashElement('Farmer Puzzle Hash', block.farmer_puzzle_hash);
    farmerPuzzleHashElement.className += ' line';
    farmerPuzzleHashElement.style.animation = `typing 1s steps(40, end) 0.2s forwards`;
    blockRecordSection.appendChild(farmerPuzzleHashElement);

    // Create weight line
    const weightLineElement = document.createElement('div');
    weightLineElement.textContent = `Weight: ${block.weight || 'N/A'}`;
    weightLineElement.className = 'line';
    weightLineElement.style.animation = `typing 1s steps(${weightLineElement.textContent.length}, end) 0.3s forwards`;
    blockRecordSection.appendChild(weightLineElement);

    // Create fees line
    const feesLineElement = document.createElement('div');
    feesLineElement.textContent = `Fees: ${formatXCH(block.fees || 0, isTestnet)}`;
    feesLineElement.className = 'line';
    feesLineElement.style.animation = `typing 1s steps(${feesLineElement.textContent.length}, end) 0.4s forwards`;
    blockRecordSection.appendChild(feesLineElement);

    // Add ASCII visualizer
    terminal.appendChild(createAsciiVisualizer());

    // Add speed display
    const speedDisplayElement = document.createElement('div');
    speedDisplayElement.className = 'speed-display';
    terminal.appendChild(speedDisplayElement);

    // Add blinking cursor
    const cursorElement = document.createElement('div');
    cursorElement.className = 'cursor';
    terminal.appendChild(cursorElement);

    // Update speed display
    updateSpeedDisplay(readSpeed);
  };

  const updateNotesSection = () => {
    if (!notesSectionRef.current) return;

    const notesSection = notesSectionRef.current;
    notesSection.innerHTML = '';

    const lines = [];

    if (headerHash) {
      // Remove '0x' prefix if it exists
      const displayHash = headerHash.startsWith('0x') ? headerHash.slice(2) : headerHash;
      lines.push(
        '<h3>Header Hash</h3>',
        `<p class="puzzle-hash">${highlightCurrentChar(displayHash, currentIndex)}</p>`
      );
    }

    lines.push('<h3>Playing</h3>');
    if (currentNote) {
      lines.push(
        `<span><p>Note: ${currentNote} Character: ${currentChar} Index: ${currentIndex} Conversion: ${currentChar} (hex) → ${currentNote}</p></span>`,
      );
    } else {
      lines.push('<p>No note playing</p>');
    }

    lines.forEach(line => {
      const lineElement = document.createElement('div');
      lineElement.innerHTML = line;
      notesSection.appendChild(lineElement);
    });
  };

  return (
    <div className="crt">
      
        <div className="terminal" ref={terminalRef}>
          {!block && <div className="line">Loading...</div>}
        </div>
      
    </div>
  );
}
